import React from "react";
import styled from "styled-components";
import placeholderIcon from "../assets/coins/logo-placeholder.svg";

interface TokenLogoProps extends LogoProps {
  src?: string;
  alt?: string;
  className?: string;
}

export const TokenLogo = ({
  alt = "token logo",
  className = "",
  src = placeholderIcon,
  size = 83,
}: TokenLogoProps) => (
  <Logo src={src} alt={alt} size={size} className={className} />
);

interface LogoProps {
  size?: number;
}

const Logo = styled.img<LogoProps>`
  max-width: 100%;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 50%;
`;
