import styled from "styled-components";
import { CopyButton } from "./CopyButton";
import { Media } from "../../styles";

const BaseButton = styled.button`
  width: 120px;
  height: 40px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  border-radius: 20px;
`;

export const ButtonPrimary = styled(BaseButton)`
  color: #0a0e27;
  background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
`;

export const ButtonSecondary = styled(BaseButton)`
  color: #ffffff;
  border: 1px solid #37dcf2;
`;

const BoxButtonCSS = `
width: fit-content;
display: flex;
justify-content: center;
align-items: center;
gap: 8px;
padding: 11px 20px;
font-weight: 500;
font-size: 14px;
line-height: 17px;
color: #ffffff;
background: #171B33;
border-radius: 10px;
transition: all 0.2s;

&:hover{
  background: #151530;
  box-shadow: 0px 8px 32px #0A0D21;
}

&:active{
  background: #1E2239;
}

&:disabled{
  color: #464659;
  svg {
    path {
      fill: #464659;
    }
  }
}

${Media.smallDesktop} {
  padding: 10px 12px;
}

${Media.tablet} {
  width: 100%;
}
`;

export const BoxButton = styled.button`
  ${BoxButtonCSS}
`;

export const CopyBoxButton = styled(CopyButton)`
  ${BoxButtonCSS}
`;
