import React, { useState, useEffect } from "react";
import { Notification } from "../../Icons/Notification";
import styled, { css } from "styled-components";
import { NotificationsModal } from "./NotificationsModal";
import { Media } from "../../../styles";
import { notificationsArr_Test, INotificationItem, cleanupVersion, lastUpdated } from "./notificationsInfo";

export const Notifications = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<INotificationItem[]>(notificationsArr_Test);
  const [showNotificationDot, setShowNotificationDot] = useState(true);

  useEffect(() => {
    try {
      // Testing local storage
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      
      // Set read status based on local storage 'notificationsReadStatus'
      const storedNotificationsReadStatus = JSON.parse(localStorage.getItem("shell.notificationsReadStatus") ?? "{}");
      setNotifications((currentNotifications) =>
        currentNotifications.map((notification) => ({
          ...notification,
          read: storedNotificationsReadStatus[notification.id] || notification.read,
        }))
      );

      // Clean up removed notifications based on local storage 'notificationsVersion'
      const userCleanupVersion = parseInt(localStorage.getItem('shell.notificationsVersion') ?? '0', 10);
      const shouldCleanUp = cleanupVersion > userCleanupVersion;
      
      if (shouldCleanUp) {
        cleanUpRemovedNotifications(notificationsArr_Test.map(n => n.id));
        localStorage.setItem('shell.notificationsVersion', cleanupVersion.toString());
      }

      // Show notification dot if there's a new notification
      const lastSeenUpdateDate = localStorage.getItem('shell.lastSeenUpdateDate');
      if (!lastSeenUpdateDate || new Date(lastUpdated) > new Date(lastSeenUpdateDate)) {
        setShowNotificationDot(true);
      } else {
        setShowNotificationDot(false);
      }
    }
    catch (e) {
      setShowNotificationDot(false);
      setNotifications(notifications.map(notification => ({ ...notification, read: true })));
    }
  }, []);
  
  const safeLocalStorageSetItem = (key: string, value: string): boolean => {
    try {
      localStorage.setItem(key, value);
      return true;
    } catch (e) {
      if (e instanceof DOMException && e.code === DOMException.QUOTA_EXCEEDED_ERR) {
        console.error('Local storage quota exceeded');
        return false;
      } else {
        console.error('Error setting local storage item:', e);
        return false;
      }
    }
  };
  
  const cleanUpRemovedNotifications = (currentNotificationIds: number[]) => {
    const storedNotificationsReadStatus = JSON.parse(localStorage.getItem("shell.notificationsReadStatus") ?? "{}");
  
    const storedIds = Object.keys(storedNotificationsReadStatus).map(Number);
    const idsToRemove = storedIds.filter((storedId) => !currentNotificationIds.includes(storedId));
  
    idsToRemove.forEach((idToRemove) => { delete storedNotificationsReadStatus[idToRemove]; });
  
    safeLocalStorageSetItem("shell.notificationsReadStatus", JSON.stringify(storedNotificationsReadStatus));
  };
    
  const markAsRead = (notificationId: number) => {
    setNotifications((currentNotifications) =>
      currentNotifications.map((notification) => {
        if (notification.id === notificationId) {
          return { ...notification, read: true };
        }
        return notification;
      })
    );

    const storedNotificationsReadStatus = JSON.parse(localStorage.getItem("shell.notificationsReadStatus") ?? "{}");
    storedNotificationsReadStatus[notificationId] = true;
    safeLocalStorageSetItem("shell.notificationsReadStatus", JSON.stringify(storedNotificationsReadStatus));
  };

  const handleMarkAllAsRead = () => {
    const allReadStatus = notifications.reduce((acc, notification) => {
      acc[notification.id] = true;
      return acc;
    }, {} as { [key: number]: boolean });

    safeLocalStorageSetItem("shell.notificationsReadStatus", JSON.stringify(allReadStatus));
    setNotifications((currentNotifications) =>
      currentNotifications.map((notification) => ({
        ...notification,
        read: true,
      }))
    );
    setIsOpenModal(false);
  };

  const onClose = (viewedNotificationIds?: number[] | number) => {
    setIsOpenModal(false);
    if (Array.isArray(viewedNotificationIds)) { // handles modal close
      viewedNotificationIds.forEach(markAsRead);
    } else if (typeof viewedNotificationIds === 'number') { // handles notification click
      markAsRead(viewedNotificationIds);
    } else {
      handleMarkAllAsRead();
    }
  };

  const onAllUnreadNotificationsSeen = () => {
    const lastSeenUpdateDate = localStorage.getItem('shell.lastSeenUpdateDate');
    if (!lastSeenUpdateDate || new Date(lastUpdated) > new Date(lastSeenUpdateDate)) {
      safeLocalStorageSetItem('shell.lastSeenUpdateDate', lastUpdated);
      setShowNotificationDot(false);
    }
  };
  
  return (
    <>
      <Container
        showNotificationDot={showNotificationDot}
        isOpenModal={isOpenModal}
        onClick={() => setIsOpenModal(!isOpenModal)}
      >
        <Notification />
      </Container>
      <NotificationsModal
        notifications={notifications}
        isVisible={isOpenModal}
        onClose={onClose}
        onMarkAllAsRead={handleMarkAllAsRead}
        onAllUnreadNotificationsSeen={onAllUnreadNotificationsSeen} 
      />
    </>
  );
};

const Container = styled.div<{ 
  showNotificationDot: boolean; 
  isOpenModal: boolean; 
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px;
  margin-right: 12px;
  background: #0a0e27;
  border: 2px solid transparent;
  border-radius: 16px;
  position: relative;
  cursor: pointer;

  ${({ isOpenModal }) =>
    isOpenModal &&
    css`
      border-color: #00bdff;
    `};

  ${({ showNotificationDot }) =>
    showNotificationDot &&
    css`
      &::after {
        content: "";
        position: absolute;
        top: 10px;
        right: 14px;
        background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
        border: 3px solid #0a0e27;
        width: 9px;
        height: 9px;
        border-radius: 50%;
      }
    `};

  ${Media.tablet} {
    margin-right: 8px;
    padding: 8px;
    svg {
      transform: scale(0.85);
    }
    ${({ showNotificationDot }) =>
      showNotificationDot &&
      css`
        &::after {
          content: "";
          position: absolute;
          top: 7px;
          right: 7px;
          background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
          border: 3px solid #0a0e27;
          width: 9px;
          height: 9px;
          border-radius: 50%;
        }
      `};
  }
`;
