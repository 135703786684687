export const breakpoints = {
  smallMobile: 375,
  mobile: 520,
  tablet: 768,
  smallDesktop: 1024,
  desktop: 1366,
}

export const Media = {
  smallMobile: `@media screen and (max-width: ${breakpoints.smallMobile}px)`,
  mobile: `@media screen and (max-width: ${breakpoints.mobile}px)`,
  tablet: `@media screen and (max-width: ${breakpoints.tablet}px)`,
  smallDesktop: `@media screen and (max-width: ${breakpoints.smallDesktop}px)`,
  desktop: `@media screen and (max-width: ${breakpoints.desktop}px)`,
}

