import React from 'react'
import styled, { keyframes } from "styled-components";
import bounceLoader from '../assets/icons/loader-bounce.svg'
import straightLoader from '../assets/icons/loader-straight.svg'
import spinner from '../assets/icons/spinner.svg'
import shellLoadGif from '../assets/icons/shell-load.gif'

export const BounceLoader = () => (
    <Balance src={bounceLoader} alt='loader'/>
)

export const StraightLoader = () => (
    <Straight src={straightLoader} alt='loader' />
)

export const LogoLoader = () => (
  <Points src={shellLoadGif} alt='loader'/>
);

export const Spinner = () => (
  <Spin src={spinner} alt="spinner"/>
)

export const ComputeSpinner = () => (
    <Compute src={spinner} alt="compute-spinner"/>
)

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spin = styled.img`
  animation: ${rotate} 2s linear infinite;
`;

const Compute = styled.img`
  width: 52px;
  height: 52px;
  animation: ${rotate} 2s linear infinite;
`;

const Balance = styled.img`
  height: 24px;
  width: 24px;
  margin-left: auto;
`;

const Straight = styled.img`
  width: 28px;
`;

const Points = styled.img`
  width: 150px;
`;