import React from 'react'
import styled from 'styled-components';

interface SortIconProps {
  isSorted: boolean
  isSortedDesc?: boolean
}

export const SortIcon = ({isSorted, isSortedDesc = true}: SortIconProps) => {
  return (
    <Icon isSorted={isSorted} isSortedDesc={isSortedDesc} width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1.39941L5 5.39941L9 1.39941" strokeWidth="1.6" strokeLinecap="round"/>
    </Icon>
  )
}

const Icon = styled.svg<SortIconProps>`
  transform: ${({isSortedDesc}) => isSortedDesc ? 'rotate(0)' : 'rotate(180deg)'};

  path {
    stroke: ${({isSorted}) => isSorted ? '#00BDFF' : 'white'};
  }
`;

