import { formatDistanceToNow } from "date-fns";

export const timeToNow = (date: Date): string => {
  const distance = formatDistanceToNow(date, { addSuffix: true });
  let formattedDistance = distance
    .replace("minutes", "m")
    .replace("minute", "m")
    .replace("hours", "h")
    .replace("hour", "h")
    .replace("days", "d")
    .replace("day", "d")
    .replace("months", "mo")
    .replace("month", "mo")
    .replace("years", "y")
    .replace("year", "y")
    .replace("about ", "")
    .replace("less than ", "")
    .replace("over ", "")
    .replace("almost ", "")
    .replace("half a", "0.5");

  // Removing extra space between the number and the unit
  formattedDistance = formattedDistance.replace(" ", "");
  
  return formattedDistance;
};
