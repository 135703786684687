import { createSlice } from '@reduxjs/toolkit'
interface State {
    currentSeason: any,
    historicalSeason: any
    boosts: any
}

const initialState : State = {currentSeason: {}, historicalSeason: {}, boosts: {}}

const pointsSlice = createSlice({
  name: 'points',
  initialState,
  reducers: {
      updateCurrentPoints: (state, action) => {
          let newCurrentSeason = {...state.currentSeason}
          newCurrentSeason[action.payload.address] = action.payload.value
          return {
              ...state,
              currentSeason: newCurrentSeason
          }
      },
      clearCurrentPoints: (state, action) => {
        let newCurrentSeason = {...state.currentSeason}
        delete newCurrentSeason[action.payload.address]
        return {
            ...state,
            currentSeason: newCurrentSeason
        }
      },
      updateHistoricalPoints: (state, action) => {
          const { address, season, value } = action.payload;
          const newHistoricalSeason = {
            ...state.historicalSeason,
            [address]: {
                ...state.historicalSeason[address],
                [season]: value,
            },
          };
          return {
              ...state,
              historicalSeason: newHistoricalSeason
          }
      },
      updateBoosts: (state, action) => {
        const { address, name, value } = action.payload;
        const newBoosts = {
          ...state.boosts,
          [address]: {
              ...state.boosts[address],
              [name]: value,
          },
        };
        return {
            ...state,
            boosts: newBoosts
        }
      },
      clearPoints: () => {
          return initialState
      }
  }
})

export const { updateCurrentPoints, clearCurrentPoints, updateHistoricalPoints, updateBoosts, clearPoints } = pointsSlice.actions

export default pointsSlice.reducer