import { useState, useEffect, RefObject } from 'react';

export const useScrollbarVisible = (ref: RefObject<HTMLElement>) => {
  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);

  useEffect(() => {
    const checkScrollbarVisibility = () => {
      const element = ref.current;
      if (element) {
        setIsScrollbarVisible(element.scrollHeight > element.clientHeight);
      }
    };

    checkScrollbarVisibility();

    window.addEventListener('resize', checkScrollbarVisibility);
    return () => {
      window.removeEventListener('resize', checkScrollbarVisibility);
    };
  }, [ref]);

  return isScrollbarVisible;
};
