import React from 'react'
import styled from 'styled-components'

interface DropdownIconProps {
  className?: string
}

export const DropdownIconDown = ({ className }: DropdownIconProps) => (
  <SVGBase viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M1 1L6 6L11 1" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </SVGBase>
)

export const DropdownIconUp = ({ className }: DropdownIconProps) => (
  <SVGBase viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M11 7L6 2L1 7" stroke="white" strokeWidth="2" strokeLinecap="round" />
  </SVGBase>
)

export const SVGBase = styled.svg`
  width: 12px;
  height: 8px;
`