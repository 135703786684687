import React from "react";
import copyIcon from "../../assets/icons/copy.svg";
import { copyTextToClipboard } from "../../utils/copy";
import styled from "styled-components";

interface CopyButtonProps {
  text: string;
  className?: string;
  children?: React.ReactNode;
  icon?: string;
  cIcon?: JSX.Element;
  showText?: boolean;
}

export const CopyButton = ({
  text,
  className = "",
  icon,
  children,
  cIcon,
  showText
}: CopyButtonProps) => {
  const [copied, setCopied] = React.useState(false);
  return (
    <button
      className={className}
      onClick={() => {
        copyTextToClipboard(text);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      }}
    >
      {icon && <Icon src={icon} alt="icon" />}
      <Text>{children && children}</Text>
      {copied ? (
        <CopiedContainer>
          <svg
            width="20"
            height="24"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.6663 5L7.49967 14.1667L3.33301 10"
              stroke="url(#paint0_linear_3858_25284)"
              strokeWidth="1.66667"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <defs>
              <linearGradient
                id="paint0_linear_3858_25284"
                x1="3.44967"
                y1="5"
                x2="16.7676"
                y2="5.14962"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#37DCF2" />
                <stop offset="1" stopColor="#07C0FB" />
              </linearGradient>
            </defs>
          </svg>
          {showText && 'Copied'}
        </CopiedContainer>
      ) : (
        cIcon || <Icon src={cIcon || copyIcon} alt="copy" />
      )}
    </button>
  );
};

const Icon = styled.img`
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
`;

const CopiedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const Text = styled.div`
  word-break: break-all;
  text-align: start;
`;
