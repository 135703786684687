import { createSlice } from '@reduxjs/toolkit'
interface State {
    leaderboards: {[season: string] : any},
    dates: {[season: string] : string}
}

const initialState : State = {leaderboards: {}, dates: {}}

const leaderboardsSlice = createSlice({
  name: 'leaderboards',
  initialState,
  reducers: {
      addLeaderboard: (state, action) => {
          let newLeaderboards = {...state.leaderboards}
          newLeaderboards[action.payload.id] = action.payload.value
          let newDates = {...state.dates}
          newDates[action.payload.id] = action.payload.date
          return {
              ...state,
              leaderboards: newLeaderboards,
              dates: newDates
          }
      },
      clearLeaderboards: () => {
          return initialState
      }
  }
})

export const { addLeaderboard, clearLeaderboards } = leaderboardsSlice.actions

export default leaderboardsSlice.reducer