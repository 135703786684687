export const LBPPoolABI = [
  {
    inputs: [
      { internalType: "int128", name: "_py_init", type: "int128" },
      { internalType: "int128", name: "_px_init", type: "int128" },
      { internalType: "int128", name: "_py_final", type: "int128" },
      { internalType: "int128", name: "_px_final", type: "int128" },
      {
        internalType: "uint256",
        name: "_curveEvolutionStartTime",
        type: "uint256",
      },
      {
        internalType: "uint256",
        name: "_curveEvolutionDuration",
        type: "uint256",
      },
    ],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  { inputs: [], name: "AmountError", type: "error" },
  {
    inputs: [
      { internalType: "int256", name: "x", type: "int256" },
      { internalType: "int256", name: "y", type: "int256" },
    ],
    name: "BalanceError",
    type: "error",
  },
  {
    inputs: [
      { internalType: "int256", name: "x", type: "int256" },
      { internalType: "int256", name: "y", type: "int256" },
    ],
    name: "BoundaryError",
    type: "error",
  },
  {
    inputs: [{ internalType: "int256", name: "errorValue", type: "int256" }],
    name: "CurveError",
    type: "error",
  },
  { inputs: [], name: "InvalidPrice", type: "error" },
  { inputs: [], name: "MaximumAllowedPriceExceeded", type: "error" },
  { inputs: [], name: "MaximumAllowedPriceRatioExceeded", type: "error" },
  { inputs: [], name: "MinimumAllowedPriceExceeded", type: "error" },
  { inputs: [], name: "PoolNotActiveYet", type: "error" },
  {
    inputs: [],
    name: "BASE_FEE",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "a",
    outputs: [{ internalType: "int128", name: "", type: "int128" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "b",
    outputs: [{ internalType: "int128", name: "", type: "int128" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "curveEvolutionDuration",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "xBalance", type: "uint256" },
      { internalType: "uint256", name: "yBalance", type: "uint256" },
      { internalType: "uint256", name: "totalSupply", type: "uint256" },
      { internalType: "uint256", name: "depositedAmount", type: "uint256" },
      {
        internalType: "enum SpecifiedToken",
        name: "depositedToken",
        type: "uint8",
      },
    ],
    name: "depositGivenInputAmount",
    outputs: [
      { internalType: "uint256", name: "mintedAmount", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "xBalance", type: "uint256" },
      { internalType: "uint256", name: "yBalance", type: "uint256" },
      { internalType: "uint256", name: "totalSupply", type: "uint256" },
      { internalType: "uint256", name: "mintedAmount", type: "uint256" },
      {
        internalType: "enum SpecifiedToken",
        name: "depositedToken",
        type: "uint8",
      },
    ],
    name: "depositGivenOutputAmount",
    outputs: [
      { internalType: "uint256", name: "depositedAmount", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "elapsed",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "p_max",
    outputs: [{ internalType: "int128", name: "", type: "int128" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "p_min",
    outputs: [{ internalType: "int128", name: "", type: "int128" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "params",
    outputs: [
      { internalType: "int128", name: "", type: "int128" },
      { internalType: "int128", name: "", type: "int128" },
      { internalType: "int128", name: "", type: "int128" },
      { internalType: "int128", name: "", type: "int128" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
      { internalType: "uint256", name: "", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "px_final",
    outputs: [{ internalType: "int128", name: "", type: "int128" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "px_init",
    outputs: [{ internalType: "int128", name: "", type: "int128" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "py_final",
    outputs: [{ internalType: "int128", name: "", type: "int128" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "py_init",
    outputs: [{ internalType: "int128", name: "", type: "int128" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "xBalance", type: "uint256" },
      { internalType: "uint256", name: "yBalance", type: "uint256" },
      { internalType: "uint256", name: "inputAmount", type: "uint256" },
      {
        internalType: "enum SpecifiedToken",
        name: "inputToken",
        type: "uint8",
      },
    ],
    name: "swapGivenInputAmount",
    outputs: [
      { internalType: "uint256", name: "outputAmount", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "xBalance", type: "uint256" },
      { internalType: "uint256", name: "yBalance", type: "uint256" },
      { internalType: "uint256", name: "outputAmount", type: "uint256" },
      {
        internalType: "enum SpecifiedToken",
        name: "outputToken",
        type: "uint8",
      },
    ],
    name: "swapGivenOutputAmount",
    outputs: [
      { internalType: "uint256", name: "inputAmount", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "t",
    outputs: [{ internalType: "int128", name: "", type: "int128" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "t_final",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "t_init",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "xBalance", type: "uint256" },
      { internalType: "uint256", name: "yBalance", type: "uint256" },
      { internalType: "uint256", name: "totalSupply", type: "uint256" },
      { internalType: "uint256", name: "burnedAmount", type: "uint256" },
      {
        internalType: "enum SpecifiedToken",
        name: "withdrawnToken",
        type: "uint8",
      },
    ],
    name: "withdrawGivenInputAmount",
    outputs: [
      { internalType: "uint256", name: "withdrawnAmount", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "uint256", name: "xBalance", type: "uint256" },
      { internalType: "uint256", name: "yBalance", type: "uint256" },
      { internalType: "uint256", name: "totalSupply", type: "uint256" },
      { internalType: "uint256", name: "withdrawnAmount", type: "uint256" },
      {
        internalType: "enum SpecifiedToken",
        name: "withdrawnToken",
        type: "uint8",
      },
    ],
    name: "withdrawGivenOutputAmount",
    outputs: [
      { internalType: "uint256", name: "burnedAmount", type: "uint256" },
    ],
    stateMutability: "view",
    type: "function",
  },
];