
const ELEVEN_BYTES_OF_ZERO = "0x0000000000000000000000"
const THIRTY_TWO_BYTES_OF_ZERO = "0x0000000000000000000000000000000000000000000000000000000000000000"
const OCEAN_NORMALIZED_DECIMALS = "18";

export {
    ELEVEN_BYTES_OF_ZERO,
    THIRTY_TWO_BYTES_OF_ZERO,
    OCEAN_NORMALIZED_DECIMALS
}
