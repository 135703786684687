import React from "react";
import styled from "styled-components";

interface ToggleProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ToggleSkinny = ({
    checked,
    onChange,
  }: ToggleProps) => (
    <Container>
      <ToggleInput
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
    </Container>
);
  
const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ToggleInput = styled.input`
  -webkit-appearance: none;
  width: 40px;
  height: 10px;
  background: #171b33;
  outline: none;
  border-radius: 10px;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;

  &:checked {
    background: #1fcef7;
  }

  &:before {
    content: "";
    position: absolute;
    background: #ffffff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: -6px;
    left: 0px;
    transition: left 0.2s;
  }

  &:checked:before {
    left: 22px;
  }
`;
