export const scrollbar = ({
  width,
  scrollbarColor,
  backgroundColor,
  marginTop,
  marginBottom,
  border,
}: {
  width?: string;
  scrollbarColor?: string;
  backgroundColor?: string;
  marginTop?: string;
  marginBottom?: string;
  border?: string;
} = {}): string =>
  `
    &::-webkit-scrollbar {
        width: ${width || "4px"};
    }

    &::-webkit-scrollbar-track {
        margin-top: ${marginTop || "0px"};
        margin-bottom: ${marginBottom || "0px"};
        background-color: ${backgroundColor || "#1E2239"};
        border-radius: ${border || "5px"};
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${scrollbarColor || "#2C5173"};
        border-radius: ${border || "5px"};
    }
`;
