import { createSlice } from '@reduxjs/toolkit'
interface State {
    metadata: {[tokenID: string] : any},
    priceChart: {[tokenID: string] : any}
}

const initialState : State = {metadata: {}, priceChart: {}}

const lbpsSlice = createSlice({
  name: 'lbps',
  initialState,
  reducers: {
      addMetadata: (state, action) => {
          let newMetadata = {...state.metadata}
          newMetadata[action.payload.name] = action.payload.data
          return {
              ...state,
              metadata: newMetadata
          }
      },
      addPriceChart: (state, action) => {
        let newPriceChart = {...state.priceChart}
        newPriceChart[action.payload.name] = action.payload.data
        return {
            ...state,
            priceChart: newPriceChart
        }
      },
      clear: () => {
          return initialState
      }
  }
})

export const { addMetadata, addPriceChart, clear } = lbpsSlice.actions

export default lbpsSlice.reducer