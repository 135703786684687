import React, { useEffect, useState } from 'react'
import slidersIcon from "../../assets/icons/sliders-solid.svg";
import styled, { css } from 'styled-components';
import { Modal } from '../../components/Modal/Modal';
import { ConfirmIcon } from '../../components/Icons/ConfirmIcon';
import { Media } from '../../styles';

export const DEFAULT_SLIPPAGE = 0.5; // Percentage

interface SettingsModalProps {
  userSlippage: number,
  setUserSlippage: (slippage: number) => void
}

export const SettingsModal = ({ userSlippage, setUserSlippage }: SettingsModalProps) => {

  const [isVisible, setIsVisible] = useState(false);
  const [auto, setAuto] = useState(true)

  const [displaySlippage, setDisplaySlippage] = useState('');
  const [confirmDisabled, setConfirmDisabled] = useState(true)

  const setDefault = () => {
    setAuto(true)
    setDisplaySlippage(DEFAULT_SLIPPAGE.toFixed(2))
  }

  const handleSlippageInput = (value: string) => {
    if (value && /^\d*\.?\d*$/.test(value)) { // Value is not empty and is a parseable number
      setAuto(false)
      const numValue = parseFloat(value)
      if (numValue > 100) {
        setDisplaySlippage('100')
      } else {
        setDisplaySlippage(
          value.includes('.') && value.split('.')[1].length > 2 ? // Limit to two decimals in display
            value.substring(0, value.indexOf('.') + 3) :
            value
        )
      }
    } else {
      setDefault()
    }

    setConfirmDisabled(false)
  }

  const confirmSettings = () => {
    setUserSlippage(parseFloat(displaySlippage))
    setIsVisible(false)
  }

  useEffect(() => {
    if (isVisible) {
      setDisplaySlippage(userSlippage == DEFAULT_SLIPPAGE ? '' : userSlippage.toFixed(2))
    }
  }, [isVisible])

  return (
    <>
      <SettingsButton onClick={() => {
        setConfirmDisabled(true)
        setIsVisible(true)
      }}>
        <img src={slidersIcon} alt="settings" />
      </SettingsButton>
      <StyledModal
        title={'Trade Settings'}
        isVisible={isVisible}
        onClose={() => setIsVisible(false)}
      >
        <Row>
          <Label>Slippage Tolerance</Label>

          <ValueRow>
            <AutoButton auto={auto} onClick={setDefault}>Auto</AutoButton>
            <SlippageInput placeholder={DEFAULT_SLIPPAGE.toFixed(2)} value={auto ? '' : displaySlippage} onChange={(e) => handleSlippageInput(e.target.value)} inputMode={'decimal'} />
            %
          </ValueRow>
        </Row>
        <ConfirmButton onClick={confirmSettings} disabled={confirmDisabled}>
          <ConfirmIcon />
          Update
        </ConfirmButton>
      </StyledModal>
    </>
  )
}

const SettingsButton = styled.button`
  width: 36px;
  height: 36px;

  &:hover {
   opacity: 0.7;
  }
`;

const StyledModal = styled(Modal)`
  height: fit-content;

  ${Media.mobile} {
    height: 40%;
    max-width: 100%;
    margin-top: 55.5%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  ${Media.smallMobile} {
    height: 45%;
    margin-top: 50.5%;
  }
`;

const Label = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  text-align: left;
  color: #7D7D97;
  white-space: nowrap;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
`;

const ValueRow = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;

const autoFocusStyle = css`
    background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
    color: #000E47;
    border: solid 2px #000E47;
`

const AutoButton = styled.button <{ auto: boolean }>`
  margin-left: 10px;
  margin-right: 10px;
  border: none;
  border: solid 2px white;
  border-radius: 6px;
  background: rgba(255, 255, 255,0);
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;
  height: 70%;

  :hover {
    ${autoFocusStyle}
  }

  ${({ auto }) => auto && css`
    ${autoFocusStyle}
  `};

  ${Media.mobile}{
    margin-right: 8px;
    border-radius: 16px;
  }


`

const inputTextStyles = css`
  font-size: 16px;
  line-height: 19px;
`

const placeholderStyles = css`
  ${inputTextStyles}
  color: #7D7D97;
`

export const SlippageInput = styled.input`
  width: 25%;
  padding-bottom: 4px;
  margin-right: 4px;
  color: #FFFFFF;
  text-align: right;
  border: none;
  border-bottom: 1px solid #FFFFFF;
  background: transparent;
  outline: none;
  ${inputTextStyles}

  &:focus {
    border-color: #FFFFFF;
  }

  &::-webkit-input-placeholder {
    ${placeholderStyles}
  }
  &::-moz-placeholder {
    ${placeholderStyles}
  }
  &:-ms-input-placeholder {
    ${placeholderStyles}
  }
  &:-moz-placeholder {
    ${placeholderStyles}
  }
`;

const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 76px;
  margin-top: 24px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0a0e27;
  background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
  border-radius: 16px;

  svg {
    height: 28px;
    width: 28px;
    margin-right: 8px;
  }

  &:not(:disabled) svg path {
    fill: #151530;
  }

  &:disabled {
    background: #151530;
    color: #464659;
  }

  ${Media.tablet} {
    height: 66px;
    margin: 32px 0;
  }
`;
