import React from "react";
import styled, { css } from 'styled-components';
import { Modal } from "../../components/Modal/Modal";
import { ErrorIcon } from "../../components/Icons/ErrorIcon";
import { formatDisplay } from "../../utils/formatDisplay";
import { Media } from "../../styles";

interface ImpactModalProps {
    visible: boolean,
    setVisible: (visible : boolean) => void,
    priceImpact: number,
    setTradeDisabled: (disabled: boolean) => void,
    setConfirmVisible: (visible : boolean) => void,
}

export const ImpactModal = ({
  visible,
  setVisible,
  priceImpact,
  setTradeDisabled,
  setConfirmVisible
}: ImpactModalProps) => {

  return (
    <StyledModal dataTestId="price-impact-modal" isVisible={visible} onClose={() => setVisible(false)}>
      <Title>Warning</Title>
      <ErrorIcon/>
      <Text>The projected price impact of this trade is</Text>
      <PriceImpact value={priceImpact}>{`${formatDisplay(priceImpact.toString(), 2)}%`}</PriceImpact>
      <Text>Are you sure you wish to proceed?</Text>
      <ActionButton data-testid="take-me-back-btn" onClick={() => {
        setTradeDisabled(false)
        setVisible(false)
      }}>No, take me back</ActionButton>
      <ModalButton data-testid="proceed-anyways-btn" onClick={() => {
        setVisible(false)
        setConfirmVisible(true)
      }}>{`Proceed anyways \u2192`}</ModalButton>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
    color: #00BDFF;
    height: 524px;
    padding-bottom: 0;

    svg {
        margin: 24px auto 24px;
        height: 128px;
        width: 128px;
    }

    ${Media.mobile} {
        height: 95%;
        max-width: 100%;
        margin-top: 5.5%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

`

const Title = styled.h3`
  margin-top: 12px;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #ffffff;
`;

const PriceImpact = styled(Title)<{value: number }>`

  margin-top: 8px;
  margin-bottom: 8px;

  ${({ value }) => value <= -5 && css`
    color: #FF5349;
  `};
  ${({ value }) => value > -5 && value <= -1  && css`
    color: #DAA520;  
  `};
`;

const Text = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  color: #7d7d97;
  text-align: center;
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 264px;
  height: 76px;
  margin: 12px auto 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0a0e27;
  background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
  border-radius: 16px;

  svg {
    height: 28px;
    width: 28px;
    margin-right: 8px;
    color: #464659;
  }

  &:not(:disabled) svg path {
    fill: #151530;
  }

  &:disabled {
    background: #0a0e27;
    color: #464659;
    border: solid 2px #464659;
  }
`;

const ModalButton = styled.button`
  margin-top: 8px;
  font-weight: 100;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #7D7D97;
  white-space: nowrap;
  letter-spacing: -0.02em;

  :hover {
      color: #00BDFF;
  }
`;


