import { keccak256 as solidityKeccak256 } from "@ethersproject/solidity";
import { BigNumber, BigNumberish } from "@ethersproject/bignumber";
import { isAddress } from "@ethersproject/address";
import { hexConcat, hexDataSlice, isHexString } from "@ethersproject/bytes";
import { MaxUint256, Zero } from "@ethersproject/constants"
import { ELEVEN_BYTES_OF_ZERO } from "./constants";
import { Interaction, InteractionCode } from "./types"
import { WRAPPED_ETHER_ID } from "../../constants/addresses";
import { unwrapEther } from "./interactions";

function removeLeadingZeros(hexString : string) {
    // Remove the "0x" prefix if present
    if (hexString.startsWith("0x")) {
      hexString = hexString.slice(2);
    }
  
    // Remove all leading "0" digits
    while (hexString.startsWith("0")) {
      hexString = hexString.slice(1);
    }
  
    return "0x" + hexString;
}

const calculateWrappedTokenId = (address: string, id: BigNumberish): string => {
    return removeLeadingZeros(solidityKeccak256(["address", "uint256"], [address, id]));
}

const idsFromInteractions = (interactions: Interaction[]): BigNumber[] => {
    const interactionToIds = (interaction: Interaction) => {
        const interactionIds: BigNumberish[] = [];
        const { interactionType, address } = unpackInteractionTypeAndAddress(
            interaction.interactionTypeAndAddress
        );
        if (interactionType === InteractionCode.Erc20Wrap ||
            interactionType === InteractionCode.Erc20Unwrap) {
            interactionIds.push(calculateWrappedTokenId(address, 0));
        } else if (
            interactionType === InteractionCode.Erc721Wrap
            || interactionType === InteractionCode.Erc721Unwrap
            || interactionType === InteractionCode.Erc1155Wrap
            || interactionType === InteractionCode.Erc1155Unwrap
        ) {
            interactionIds.push(calculateWrappedTokenId(
                address,
                interaction.metadata
            )
            );
        } else if (
            interactionType === InteractionCode.ComputeInputAmount
            || interactionType == InteractionCode.ComputeOutputAmount
        ) {
            interactionIds.push(interaction.inputToken);
            interactionIds.push(interaction.outputToken);
        } else if(interactionType === InteractionCode.EtherWrap || interactionType === InteractionCode.EtherUnwrap){
            interactionIds.push(WRAPPED_ETHER_ID)
        } else {
            throw new Error("INVALID INTERACTION TYPE");
        }
        return interactionIds;
    }
    // for each interaction, determine the relevant unified ledger IDs
    const idsArrayNested = interactions.map((interaction) => interactionToIds(interaction));
    // flatten the nested arrays and take the set to find unique ids
    const idsSet = new Set(idsArrayNested.flat());
    // Set.values() returns an iterator, spread it into a list
    const idsList = [...idsSet.values()];
    // because of the 
    const ids = idsList.map((id) => BigNumber.from(id));
    return ids;
}

const wrapEtherFilter = (interactions : Interaction[]): [Interaction[], BigNumberish] => {

    let etherAmount : BigNumber = Zero

    for (var i = 0; i < interactions.length; i++) {

        const { interactionType } = unpackInteractionTypeAndAddress(interactions[i].interactionTypeAndAddress);

        if(interactionType === InteractionCode.EtherWrap){

            if(interactions[i].specifiedAmount == MaxUint256 && interactions[i-1].inputToken == WRAPPED_ETHER_ID){
                let j = 1
                while(i - j >= 0 && interactions[i-j].inputToken == WRAPPED_ETHER_ID){  
                    etherAmount = etherAmount.add(interactions[i-j].metadata) // Max Ether amount that user is willing to give after slippage limit
                    j++
                }
                interactions[i] = unwrapEther(MaxUint256)
            } else {
                etherAmount = etherAmount.add(interactions[i].specifiedAmount)

                if(
                    i > 0 && interactions[i-1].specifiedAmount == MaxUint256 && 
                    unpackInteractionTypeAndAddress(
                        interactions[i-1].interactionTypeAndAddress
                    ).interactionType == InteractionCode.EtherUnwrap
                ){ // Previous step is max ether unwrap
                    interactions.splice(i-1, 2)
                } else {
                    interactions.splice(i, 1)
                }
            }
        }
    }

    return [interactions, etherAmount]
}

const packInteractionTypeAndAddress = (
    interaction: InteractionCode,
    address: string
): string => {
    console.assert(isAddress(address));
    return hexConcat([interaction, ELEVEN_BYTES_OF_ZERO, address]);
}

const numberWithFixedDecimals = (number: BigNumberish, decimals: BigNumberish): BigNumber => {
    const base = BigNumber.from("10");
    const mantissa = BigNumber.from(number);
    const exponent = BigNumber.from(decimals);
    return mantissa.mul(base.pow(exponent))
}

const unpackInteractionTypeAndAddress = (
    interactionTypeAndAddress: string
): { interactionType: string, address: string } => {
    console.assert(isHexString(interactionTypeAndAddress))
    const interactionType = hexDataSlice(interactionTypeAndAddress, 0, 1);
    const address = hexDataSlice(interactionTypeAndAddress, 12);
    console.assert(isAddress(address));
    return { interactionType, address };
}

const withDelta = (interaction: Interaction): Interaction => {
    return {
        ...interaction,
        specifiedAmount: MaxUint256
    }
}

export {
    removeLeadingZeros,
    calculateWrappedTokenId,
    idsFromInteractions,
    numberWithFixedDecimals,
    packInteractionTypeAndAddress,
    unpackInteractionTypeAndAddress,
    withDelta,
    wrapEtherFilter
}