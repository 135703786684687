import React from "react";

export const ConfirmIcon = ({ withGradient }: { withGradient?: boolean }) => {
  return withGradient ? (
    <svg
      width="63"
      height="62"
      viewBox="0 0 63 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5 0C14.4066 0 0.5 13.9066 0.5 31C0.5 48.0934 14.4066 62 31.5 62C48.5934 62 62.5 48.0934 62.5 31C62.5 13.9066 48.5934 0 31.5 0ZM31.5 56.3636C17.5143 56.3636 6.13636 44.9855 6.13636 31C6.13636 17.0145 17.5143 5.63636 31.5 5.63636C45.4857 5.63636 56.8636 17.0145 56.8636 31C56.8636 44.9855 45.4855 56.3636 31.5 56.3636Z"
        fill="url(#paint0_linear_2363_49685)"
      />
      <path
        d="M43.1242 20.0402L27.182 35.9823L19.8754 28.6755C18.775 27.5751 16.9905 27.5749 15.8899 28.6753C14.7893 29.7759 14.7893 31.5602 15.8899 32.6608L25.1891 41.9604C25.7176 42.4889 26.4344 42.7859 27.1818 42.7859C27.182 42.7859 27.1818 42.7859 27.182 42.7859C27.9294 42.7859 28.6461 42.4889 29.1746 41.9606L47.1097 24.0259C48.2103 22.9253 48.2103 21.141 47.1097 20.0404C46.0091 18.9398 44.2248 18.9396 43.1242 20.0402Z"
        fill="url(#paint1_linear_2363_49685)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2363_49685"
          x1="1.0425"
          y1="1.30947e-05"
          x2="62.9752"
          y2="0.478371"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37DCF2" />
          <stop offset="1" stopColor="#07C0FB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2363_49685"
          x1="15.3521"
          y1="19.2148"
          x2="48.1852"
          y2="19.5685"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#37DCF2" />
          <stop offset="1" stopColor="#07C0FB" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      fill="currentColor"
    >
      <path
        d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z"
        fill="currentColor"
      />
    </svg>
  );
};
