import React from "react";
import styled, { css } from "styled-components";
import { Media } from "../../styles";

export const StatusBullet = ({ status = "Ongoing", small = false }) => {
  return (
    <StatusContainer status={status} small={small}>
      <Bullet />
      <LiveText>{status}</LiveText>
    </StatusContainer>
  );
};

export const Bullet = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  &::after {
    content: "";
    width: 6px;
    height: 6px;
    position: absolute;
    background: var(
      --gradient-2,
      linear-gradient(135deg, #37dcf2 0%, #07c0fb 100%)
    );
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
`;

export const LiveText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
`;

export const StatusContainer = styled.div<{ status: string; small?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border: 1px solid
    ${({ status }) =>
      status === "Ongoing"
        ? "#00bdff"
        : status === "Upcoming"
        ? "#A9D1E2"
        : "#292941"};

  ${({ small }) =>
    small &&
    css`
      width: fit-content;
      height: 24px;
      ${LiveText} {
        font-size: 12px;
      }
    `};
  border-radius: 15px;
  padding: 6px 10px;

  ${Bullet} {
    background: ${({ status }) =>
      status === "Ongoing"
        ? " rgba(0, 189, 255, 0.2)"
        : status === "Upcoming"
        ? "#a9d1e233"
        : "#7d7d9733"};
    &::after {
      background: ${({ status }) =>
        status === "Ongoing"
          ? "linear-gradient(135deg, #37dcf2 0%, #07c0fb 100%)"
          : status === "Upcoming"
          ? "#A9D1E2"
          : "#7d7d97"};
    }
  }

  ${LiveText} {
    background: ${({ status }) =>
      status === "Ongoing"
        ? "linear-gradient(135deg, #37dcf2 0%, #07c0fb 100%) "
        : status === "Upcoming"
        ? "#A9D1E2"
        : "#7D7D97"};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  ${Media.tablet} {
    margin-left: auto;
  }
`;
