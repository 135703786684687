import React from "react";
import styled from "styled-components";
import { Media } from "../../styles";

interface SliderProps {
  value: number;
  max: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Slider = ({
  value,
  max,
  onChange,
  isInsideNFTsSwiperSelect = false,
}: SliderProps & { isInsideNFTsSwiperSelect?: boolean }) => (
  <SliderContainer isInsideNFTsSwiperSelect={isInsideNFTsSwiperSelect}>
    <SliderInput
      type="range"
      min={1}
      max={max}
      value={value}
      onChange={onChange}
    />
    <SliderLabel>{value}</SliderLabel>
  </SliderContainer>
);

const SliderContainer = styled.div<{ isInsideNFTsSwiperSelect?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ isInsideNFTsSwiperSelect }) =>
    isInsideNFTsSwiperSelect &&
    `
        padding-right: 1px;
        padding-left: 1px;
    `}
`;

const SliderInput = styled.input.attrs(({ value, max }) => ({
  style: {
    "--width-percentage": `${(Number(value) <= 1 ? 0 : Number(value) / Number(max)) * 100}%`,
  },
}))`
  -webkit-appearance: none;
  width: 100%;
  height: 10px;
  background: #171b33;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 10px;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.2);

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #1fcef7;
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: #1fcef7;
    cursor: pointer;
    border-radius: 50%;
    position: relative;
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  &::before {
    content: "";
    position: absolute;
    background: #1fcef7;
    height: 10px;
    width: var(--width-percentage);
    border-radius: 5px;
    z-index: 1;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

`;

const SliderLabel = styled.span`
  margin-left: 12px;
`;