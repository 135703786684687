import styled from "styled-components";
import { SVGBase } from "./Icons/DropdownIcon";

export const DropdownToggle = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 48px;
  padding: 12px 28px 12px 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #FFFFFF;
  background: #1E2239;
  border: 1px solid #292941;
  border-radius: 16px;

  ${SVGBase} {
    position: absolute;
    right: 16px;
  }
`;

export const Dropdown = styled.div`
  position: relative;
  width: 200px;

  &.expanded ${DropdownToggle} {
    border: 1px solid #2C5173;
  }
`;

export const DropdownContent = styled.ul`
  position: absolute;
  left: 0;
  bottom: -4px;
  transform: translateY(100%);
  width: 100%;
  padding: 11px 14px 11px 9px;
  background: #1E2239;
  border: 1px solid #2C5173;
  box-sizing: border-box;
  border-radius: 16px;
  z-index: 2;

  &:empty {
    display: none;
  }
`;

export const DropdownButton = styled.button`
  width: 100%;
  padding: 9px 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: #7D7D97;

  &.active {
    color: #FFFFFF;
  }

  &:hover {
    background: #151530;
    border-radius: 8px;
  }
`;


