import styled, { css } from "styled-components";
import searchIcon from "../../assets/icons/search.svg"
import searchWhiteIcon from "../../assets/icons/search-white.svg"

const inputTextStyles = css`
  font-size: 16px;
  line-height: 19px;
`

const placeholderStyles = css`
  ${inputTextStyles}
  color: #7D7D97;
`

export const SearchInput = styled.input`
  width: 100%;
  padding: 15px 0 15px 36px;
  color: #FFFFFF;
  border: none;
  border-bottom: 1px solid #292941;
  background: url(${searchIcon}) left center no-repeat;
  outline: none;
  ${inputTextStyles}

  transition: all 0.3s;
  
  &:focus,
  &:hover,
  &:active,
  &:focus-within {
    border-color: rgb(55, 220, 242);
    background: url(${searchWhiteIcon}) left center no-repeat;
  }

  &::-webkit-input-placeholder {
    ${placeholderStyles}
  }
  &::-moz-placeholder {
    ${placeholderStyles}
  }
  &:-ms-input-placeholder {
    ${placeholderStyles}
  }
  &:-moz-placeholder {
    ${placeholderStyles}
  }
`;
