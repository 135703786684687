export const OceanPoolQueryABI = [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_ocean",
          "type": "address"
        }
      ],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "poolAddress",
          "type": "address"
        }
      ],
      "name": "getPoolState",
      "outputs": [
        {
          "components": [
            {
              "internalType": "uint256",
              "name": "xBalance",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "yBalance",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "totalSupply",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "impAddress",
              "type": "address"
            }
          ],
          "internalType": "struct PoolState",
          "name": "poolState",
          "type": "tuple"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "components": [
            {
              "internalType": "uint256",
              "name": "token",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "pool",
              "type": "address"
            },
            {
              "internalType": "uint256",
              "name": "action",
              "type": "uint256"
            }
          ],
          "internalType": "struct Step[]",
          "name": "steps",
          "type": "tuple[]"
        },
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        },
        {
          "internalType": "address[]",
          "name": "sharedPools",
          "type": "address[]"
        },
        {
          "components": [
            {
              "internalType": "uint256",
              "name": "xBalance",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "yBalance",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "totalSupply",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "impAddress",
              "type": "address"
            }
          ],
          "internalType": "struct PoolState[]",
          "name": "poolStates",
          "type": "tuple[]"
        }
      ],
      "name": "query",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        },
        {
          "components": [
            {
              "internalType": "uint256",
              "name": "xBalance",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "yBalance",
              "type": "uint256"
            },
            {
              "internalType": "uint256",
              "name": "totalSupply",
              "type": "uint256"
            },
            {
              "internalType": "address",
              "name": "impAddress",
              "type": "address"
            }
          ],
          "internalType": "struct PoolState[]",
          "name": "",
          "type": "tuple[]"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
]
