import React from "react";

export const Notification = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.2307 19.7692H25.5383V22.0769H2.46143V19.7692H4.76912V11.6923C4.76912 6.5943 8.90187 2.46155 13.9999 2.46155C19.0979 2.46155 23.2307 6.5943 23.2307 11.6923V19.7692ZM20.923 19.7692V11.6923C20.923 7.86881 17.8234 4.76924 13.9999 4.76924C10.1764 4.76924 7.07681 7.86881 7.07681 11.6923V19.7692H20.923ZM10.5383 24.3846H17.4614V26.6923H10.5383V24.3846Z"
        fill="white"
      />
    </svg>
  );
};
