import React, { useEffect, useRef, useState, useCallback } from "react";
import { INotificationItem } from "./notificationsInfo";
import styled, { css } from "styled-components";
import { NotificationItem } from "./NotificationItem";
import { Media, breakpoints } from "../../../styles";
import { useWidthBreakpoint } from "../../../hooks";
import { scrollbar } from "@/styles/scrollbar";
import { useDebounce } from '@/hooks/useDebounce';
import { useScrollbarVisible } from '@/hooks/useScrollbarVisible';

interface NotificationsModalProps {
  isVisible: boolean;
  notifications: INotificationItem[];
  onMarkAllAsRead: () => void;
  onAllUnreadNotificationsSeen: () => void;
  onClose: (viewedNotificationIds?: number[] | number) => void;
}

export const NotificationsModal = ({
  notifications,
  isVisible,
  onClose,
  onMarkAllAsRead,
  onAllUnreadNotificationsSeen,
}: NotificationsModalProps) => {
  const notificationsContainerRef = useRef<HTMLDivElement>(null);

  const isTablet = useWidthBreakpoint(breakpoints.tablet);
  const isScrollbarVisible = useScrollbarVisible(notificationsContainerRef);

  const lastSeenUpdateDate = localStorage.getItem('shell.lastSeenUpdateDate');

  const [scrollTrackingEnabled, setScrollTrackingEnabled] = useState<boolean>(false);
  const [viewedNotifications, setViewedNotifications] = useState(new Set<number>());

  const debouncedOnAllUnreadNotificationsSeen = useDebounce(() => { onAllUnreadNotificationsSeen(); }, 300);
  
  function getUnseenNotifications() {
    return [...notifications].reverse().filter(notification => 
      !notification.read && (!lastSeenUpdateDate || new Date(notification.date) > new Date(lastSeenUpdateDate))
    );
  }

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const unseenNotifications = getUnseenNotifications();
    const updatedViewedNotifications = updateViewedNotifications(unseenNotifications);

    if (updatedViewedNotifications.size === unseenNotifications.length) { 
      debouncedOnAllUnreadNotificationsSeen(); 
      setScrollTrackingEnabled(false);
    }

    setViewedNotifications(updatedViewedNotifications);
  };

  const updateViewedNotifications = (notificationsToCheck: INotificationItem[]) => {
    const newViewedNotifications = new Set<number>(viewedNotifications);

    notificationsToCheck.forEach(notification => {
      if (newViewedNotifications.has(notification.id)) return;
      if (isNotificationInView(notification.id)) {
        newViewedNotifications.add(notification.id);
      }
    });

    return newViewedNotifications;
  };

  function isNotificationInView(notificationId: number) {
    const container = notificationsContainerRef.current;
    const element = document.getElementById(`notification-${notificationId}`);
  
    if (!element || !container) {
      return false;
    }
  
    const currentScrollBottomPosition = container.scrollTop + container.clientHeight;
    const notificationRelativeTopPosition = element.offsetTop - container.offsetTop;
    return notificationRelativeTopPosition <= currentScrollBottomPosition;
  }

  useEffect(() => {
    if (isVisible && isTablet) { document.body.style.overflow = "hidden"; }
  
    if (isVisible && notifications.length > 0) {
      const newNotifications = getUnseenNotifications();
      const initiallyInViewNotifications = updateViewedNotifications(newNotifications);
      setViewedNotifications(initiallyInViewNotifications);
      
      if (initiallyInViewNotifications.size === newNotifications.length) {
        onAllUnreadNotificationsSeen();
      } else {
        setScrollTrackingEnabled(true);
      }
    }
  
    return () => { document.body.style.overflow = "auto"; };
  }, [isVisible, isTablet]);

  const handleModalClose = useCallback(() => {
    onClose([...viewedNotifications]);
    setViewedNotifications(new Set());
  }, [onClose, viewedNotifications]);

  return (
    <>
      {isVisible && !isTablet && ( <Backdrop onClick={handleModalClose} /> )}
      <NotificationsModalContainer isVisible={isVisible}>
          <Header>
            <Title>Notifications</Title>
            {isTablet ? (
              <CloseIcon
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={handleModalClose}
              >
                <rect width="32" height="32" rx="6" fill="#171B33" />
                <path
                  d="M22 10L10 22"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 10L22 22"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </CloseIcon>
            ) : (
              <MarkAsRead onClick={onMarkAllAsRead}>Mark all as read</MarkAsRead>
            )}
          </Header>
          <NotificationsContainer 
            ref={notificationsContainerRef} 
            onScroll={scrollTrackingEnabled ? handleScroll : undefined}
            isScrollbarVisible={isScrollbarVisible}>
              {[...notifications].reverse().map((notification) => (
                <NotificationItem
                  notification={notification}
                  key={(notification.id)}
                  handleNotificationClose={onClose}
                />
              ))}
          </NotificationsContainer>
          {isTablet && (
            <MarkAsRead onClick={onMarkAllAsRead}>Mark all as read</MarkAsRead>
          )}
        </NotificationsModalContainer>
      </>
  );
};

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: transparent;
`;

const NotificationsModalContainer = styled.div<{ isVisible: boolean }>`
  width: 100%;
  height: 550px;
  background: #0a0e27;
  border: 1px solid #171b33;
  box-shadow: 0px 16px 120px rgba(10, 14, 39, 0.5);
  border-radius: 20px;
  position: absolute;
  padding-block: 24px;
  left: 0;
  bottom: -8px;
  transform: translateY(100%);
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  z-index: 10000;

  ${({ isVisible }) =>
    isVisible &&
    css`
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    `};

  ${Media.tablet} {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    transform: translateY(0);
    border-radius: 0;
    display: flex;
    flex-direction: column;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px 24px;
  border-bottom: 1px solid #171b33;

  ${Media.tablet} {
    padding: 0 16px 20px;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: -0.03em;
  color: #ffffff;

  ${Media.tablet} {
    font-size: 20px;
  }
`;

const MarkAsRead = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;
  cursor: pointer;

  ${Media.tablet} {
    text-align: center;
    margin-top: auto;
    padding-top: 20px;
    border-top: 1px solid #171b33;
  }
`;

const NotificationsContainer = styled.div<{ isScrollbarVisible: boolean }>`
  ${scrollbar({ width: "4px", marginTop: "8px", marginBottom: "8px" })}
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin-right: ${({ isScrollbarVisible }) => isScrollbarVisible ? '4px' : '0'};
  padding: ${({ isScrollbarVisible }) => isScrollbarVisible ? '8px 6px 8px 8px' : '8px'};
  max-height: 470px;
  height: inherit;
  overflow-y: auto;
  box-sizing: border-box;
  border-radius: 20px;

  ${Media.tablet} {
    max-height: 100%;
  }
`;

const CloseIcon = styled.svg`
  cursor: pointer;
`;
