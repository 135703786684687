function fallbackCopyTextToClipboard(text: string) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  textArea.style.top = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  document.execCommand('copy')
  document.body.removeChild(textArea);
}

export const copyTextToClipboard = async (text: string) => {
  if (!navigator.clipboard) return fallbackCopyTextToClipboard(text);

  await navigator.clipboard.writeText(text);
}
