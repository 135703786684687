import { useState, useEffect, useCallback } from 'react';

export const useDebounce = <T extends (...args: any[]) => any>(callback: T, delay: number): T => {
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const debouncedCallback = useCallback((...args: any[]) => {
    if (timer) clearTimeout(timer);
    const newTimer = setTimeout(() => {
      callback(...args);
    }, delay);
    setTimer(newTimer);
  }, [callback, delay, timer]);

  useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [timer]);

  return debouncedCallback as T;
}

