/*
 * Notification Management
 * -----------------------
 * ADD NEW NOTIFICATION:
 * - Increment the highest 'id' by 1 for the new notification.
 * - For new notifications, 'read' should always be set to 'false', and other properties can vary.
 * - Ensure the 'date' (UTC) for each new notification is later than that of any existing notifications, to ensure chronological order from newest to oldest.
 * - Add the notification to the *end* of the array; the application will display them in reverse order, showing the latest first.
 * 
 * REMOVE NOTIFICATION:
 * - Comment out the notification, don't delete, to preserve the history of notifications.
 * - Increment 'cleanupVersion' to trigger the cleanup of removed notifications in the user's local storage.
 */

export interface INotificationItem {
  id: number;
  read: boolean;
  icon: string;
  title: string;
  description: string;
  date: string;
  textLink: string;
  link: string;
}

export const cleanupVersion: number = 1; // IMPORTANT - ONLY increment this number when REMOVING a notification

export const notificationsArr_Test: INotificationItem[] = [
  {
    id: 1,
    read: false,
    icon: "assets/LBP.png",
    title: "TGE Initial Unlock Vote",
    description: "The vote has concluded, and 0% has been chosen as the initial token unlock at TGE.",
    date: "2023-11-03T11:00:00Z",
    textLink: "See more",
    link: "https://snapshot.org/#/shellprotocol.eth/proposal/0xf552a1adc91b29e2fb9831d81e9eb347bb820d2ffef4a9f952cb94deacd4421d",
  },
  {
    id: 2,
    read: false,
    icon: "assets/booty/accountant-head.png",
    title: "BKB Migration",
    description: "BKB is being upgraded to a new contract. Migrate your items now.",
    date: "2023-11-10T11:00:00Z",
    textLink: "See more",
    link: "/booty",
  },
  {
    id: 3,
    read: false,
    icon: "assets/booty/countdown-crate-no-bg.png",
    title: "The Final Countdown",
    description: "TGE is almost here, get ready for the final push with the new Countdown Crate!",
    date: "2023-12-08T11:00:00Z",
    textLink: "See more",
    link: "/lbp/frACCT-21",
  },
  {
    id: 4,
    read: false,
    icon: "assets/coins/SHELL.svg",
    title: "SHELL Token Launch",
    description: "SHELL is available to trade now in the SHELL+ETH pool.",
    date: "2024-01-22T11:00:00Z",
    textLink: "See more",
    link: "/statistics/SHELL+ETH",
  },
  {
    id: 5,
    read: false,
    icon: "assets/LBP.png",
    title: "Don't See Your Tokens?",
    description: "Check the old v2 app.",
    date: "2024-02-01T11:00:00Z",
    textLink: "See more",
    link: "https://wiki.shellprotocol.io/user-guide/migrate-from-shell-v2-retired",
  }
  // {
  //   id: 2,
  //   read: false,
  //   icon: "assets/LBP.png",
  //   title: "#2: New Dynamic Multiplier System",
  //   description: "Read about the new dynamic multiplier system in the Shell Wiki.",
  //   date: "2023-06-22T11:00:00Z",
  //   textLink: "Read Wiki",
  //   link: "/lbp",
  // },
  // {
  //   id: 3,
  //   read: false,
  //   icon: "assets/LBP.png",
  //   title: "#3: Claim shCOLLAB",
  //   description: "shCOLLAB token claims are now live in the app for everyone who is eligible.",
  //   date: "2023-07-22T11:00:00Z",
  //   textLink: "Claim",
  //   link: "/lbp",
  // },
  // {
  //   id: 4,
  //   read: false,
  //   icon: "assets/LBP.png",
  //   title: "#4: New wstETH+ETH Pool",
  //   description: "New trading pool live on the app and new point multipliers as well. Go check out your points and make sure you are maximizing!",
  //   date: "2023-08-22T11:00:00Z",
  //   textLink: "View Pool",
  //   link: "/lbp",
  // },
  // {
  //   id: 5,
  //   read: false,
  //   icon: "assets/LBP.png",
  //   title: "#5: Leaderboard Update",
  //   description: "New Shell Points leaderboard is out!",
  //   date: "2023-09-22T11:00:00Z",
  //   textLink: "View Leaderboard",
  //   link: "/lbp",
  // },
  // {
  //   id: 6,
  //   read: false,
  //   icon: "assets/LBP.png",
  //   title: "#6: Leaderboard Update",
  //   description: "New Shell Points leaderboard is out!",
  //   date: "2023-10-22T11:00:00Z",
  //   textLink: "View Leaderboard",
  //   link: "/lbp",
  // },
  // {
  //   id: 7,
  //   read: false,
  //   icon: "assets/LBP.png",
  //   title: "#7: Leaderboard Update",
  //   description: "New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out!",
  //   date: "2023-11-01T12:00:00Z",
  //   textLink: "View Leaderboard",
  //   link: "/lbp",
  // },
  // {
  //   id: 8,
  //   read: false,
  //   icon: "assets/LBP.png",
  //   title: "#8: Leaderboard Update",
  //   description: "New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out!",
  //   date: "2023-11-10T01:00:00Z",
  //   textLink: "View Leaderboard",
  //   link: "/lbp",
  // },
  // {
  //   id: 9,
  //   read: false,
  //   icon: "assets/LBP.png",
  //   title: "#9: Leaderboard Update",
  //   description: "New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out!",
  //   date: "2023-11-10T02:00:00Z",
  //   textLink: "View Leaderboard",
  //   link: "/lbp",
  // },
  //   {
  //     id: 10,
  //     read: false,
  //     icon: "assets/LBP.png",
  //     title: "#10: Leaderboard Update",
  //     description: "New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out!",
  //     date: "2023-11-11T01:00:00Z",
  //     textLink: "View Leaderboard",
  //     link: "/lbp",
  //   },
  //   {
  //     id: 11,
  //     read: false,
  //     icon: "assets/LBP.png",
  //     title: "#11: Leaderboard Update",
  //     description: "New Shell Points",
  //     date: "2023-11-10T05:00:00Z",
  //     textLink: "View Leaderboard",
  //     link: "/lbp",
  //   },
  //   {
  //     id: 12,
  //     read: false,
  //     icon: "assets/LBP.png",
  //     title: "#12: Leaderboard Update",
  //     description: "New Shell Points",
  //     date: "2023-11-11T01:05:00Z",
  //     textLink: "View Leaderboard",
  //     link: "/lbp",
  //   },
  //   {
  //     id: 13,
  //     read: false,
  //     icon: "assets/LBP.png",
  //     title: "#7: Leaderboard Update",
  //     description: "New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out!",
  //     date: "2023-11-11T01:10:00Z",
  //     textLink: "View Leaderboard",
  //     link: "/lbp",
  //   },
  //   {
  //     id: 14,
  //     read: false,
  //     icon: "assets/LBP.png",
  //     title: "#13: Leaderboard Update",
  //     description: "New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out! New Shell Points leaderboard is out!",
  //     date: "2023-11-11T02:20:00Z",
  //     textLink: "View Leaderboard",
  //     link: "/lbp",
  //   },
];

export const lastUpdated: string = notificationsArr_Test[notificationsArr_Test.length - 1].date;