import React, { ReactNode } from "react";
import styled, { css } from "styled-components";

interface ToggleSwitchProps {
  dataTestId?: string;
  children: ReactNode;
  isChecked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  labelLeft?: boolean;
}

export const ToggleSwitch = ({
  dataTestId,
  children,
  isChecked,
  labelLeft = false,
  ...props
}: ToggleSwitchProps) => (
  <Toggle data-testid={dataTestId + "-toggle"}>
    <CheckBox data-testid={dataTestId + "-checkbox"} type="checkbox" checked={isChecked} {...props} />
    <ContentWrapper labelLeft={labelLeft}>
      <Switch data-testid={dataTestId + "-switch"}/>
      <Label labelLeft={labelLeft}>{children}</Label>
    </ContentWrapper>
  </Toggle>
);

const Toggle = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const ContentWrapper = styled.div<{ labelLeft?: boolean }>`
  display: flex;
  ${({ labelLeft }) => labelLeft ? css`flex-direction: row-reverse;` : css`flex-direction: row;`}
  align-items: center;
`;

const Switch = styled.div`
  display: inline-block;
  background: #171b33;
  border-radius: 16px;
  width: 52px;
  height: 29px;
  position: relative;
  vertical-align: middle;
  transition: background 0.25s;

  &:before,
  &:after {
    content: "";
  }

  &:before {
    display: block;
    background: #ffffff;
    border-radius: 50%;
    width: 21px;
    height: 21px;
    position: absolute;
    top: 4px;
    left: 4px;
    transition: left 0.25s;
  }
`;

const CheckBox = styled.input`
  position: absolute;
  visibility: hidden;

  &:checked + div ${Switch} {
    background: #1fcef7;
  }

  &:checked + div ${Switch}:before {
    left: 27px;
  }
`;

const Label = styled.span<{ labelLeft?: boolean }>`
  margin-left: ${({ labelLeft }) => (labelLeft ? '0' : '12px')};
  margin-right: ${({ labelLeft }) => (labelLeft ? '12px' : '0')};
`;
