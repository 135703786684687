import { useState, useEffect } from "react";

export const useSearch = (
  list: any[],
  searchableProperties: string[],
  searchValue: string
) => {
  const [filteredList, setFilteredList] = useState(list);

  useEffect(() => {
    if (searchValue === "") {
      setFilteredList(list);
    } else {
      const filtered = list?.filter(
        (item) =>
          searchableProperties.filter((property) => {
            let propValue = item[property];
            if (typeof propValue === "object"){
                return propValue.map((element: string) => element.toLowerCase().includes(searchValue.toLowerCase())).includes(true)
            } else {
                if (typeof propValue === "number"){
                    propValue = propValue.toString();
                    return propValue?.toLowerCase().startsWith(searchValue.toLowerCase());
                } else {
                    return propValue?.toLowerCase().includes(searchValue.toLowerCase());
                }
            }
          }).length > 0
      );
      setFilteredList(filtered);
    }
  }, [list, searchValue]);

  return filteredList;
};
