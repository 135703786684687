import React from 'react'
import styled from 'styled-components';
import { CompositionItem, Share } from './CompositionItem';

interface CompositionProps {
  list: Share[]
}

export const Composition = ({ list }: CompositionProps) => (
  <List>
    {list.map((item) => <CompositionItem key={item.symbol} asset={item} />)}
  </List>
)

export const List = styled.ul`
  display: flex;
  align-items: center;
  width: 100%;
`;